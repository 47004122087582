import * as React from "react"
import { FC, useEffect, useState } from "react"
import ProductFeatureCarousel from "./ProductFeatureCarousel"
import algoliasearch from "algoliasearch"
import { mapAlgoliaProduct } from "@social-supermarket/social-supermarket-components"
import { filterByVisibility } from "../../util/productUtil"
import { AlgoliaProductType, ProductType } from "@social-supermarket/social-supermarket-model"

const searchClient = algoliasearch("BLA29WX2H4", "253a575f90629780f8b2001e0dfe8264")
const productIndex = searchClient.initIndex("products")

interface Props {
  collectionName: string
}

const ProductFeatureCollectionCarousel: FC<Props> = ({ collectionName }) => {
  const [products, setProducts] = useState<ProductType[]>([])

  useEffect(() => {
    updateProducts()
  }, [])

  const updateProducts = async () => {
    const response = await productIndex.search<AlgoliaProductType>("", {
      filters: `collections:"${collectionName}"`,
      hitsPerPage: 200,
      clickAnalytics: true,
    })

    setProducts(response.hits.map(mapAlgoliaProduct))
  }

  return <ProductFeatureCarousel products={filterByVisibility(products, "Marketplace")} />
}

export default ProductFeatureCollectionCarousel
