import * as React from "react"
import { FC, useContext, useState } from "react"
import styled from "styled-components"
import Carousel from "./Carousel"
import { SwiperSlide } from "swiper/react"
import FlipImage from "../image/FlipImage"
import GLink from "../../../gatsby/GLink"
import { getAllImagesSmall, getPriceFromProduct, getProductMeta } from "../../util/productUtil"
import { rouge } from "../../constants/colors"
import { BasketContext } from "../../context/BasketProvider"
import { PrimaryButton, screenSizes } from "@social-supermarket/social-supermarket-components"
import { ProductType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  width: 100%;
  background-color: white;
  position: relative;
  padding: 50px 30px;
  @media (max-width: ${screenSizes.desktop}px) {
    padding: 50px 10px;
  }
`

const Title = styled.div`
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`

const Description = styled.div`
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: auto;
  font-size: 0.9em;
`
const Body = styled.div`
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: auto;

  @media (max-width: ${screenSizes.tablet}px) {
    flex-direction: column;
  }
`
const Image = styled.div`
  width: 300px;
`
const Contents = styled.div`
  padding: 20px;
  font-size: 0.9em;
`
const Price = styled.div`
  margin-top: 20px;
  font-weight: bold;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: ${screenSizes.tablet}px) {
    flex-wrap: wrap;
  }
`
const Input = styled.input`
  margin: 5px;
  width: 60px;

  @media (max-width: ${screenSizes.tablet}px) {
    width: calc(30% - 61px);
  }
`
const AddButton = styled(PrimaryButton)`
  width: 170px;
  margin: 5px;

  @media (max-width: ${screenSizes.tablet}px) {
    width: calc(70% - 61px);
  }
`
const LearnMoreButton = styled(PrimaryButton)`
  width: 170px;
  background-color: ${rouge} !important;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${screenSizes.tablet}px) {
    width: calc(100% - 10px);
  }
`

interface Props {
  products: ProductType[]
}

const ProductFeatureCarousel: FC<Props> = ({ products }) => {
  const [quantity, setQuantity] = useState<number>(1)
  const { addProduct, toggle } = useContext(BasketContext)
  const addToBasket = async product => {
    toggle(true)
    await addProduct(product, null, 1, getProductMeta(product))
  }
  return (
    <Container>
      <Carousel
        loop
        showArrows
        swiperProps={{
          slidesPerView: 1,
          slidesPerGroup: 1,
          breakpoints: {
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              allowTouchMove: true,
              pagination: true,
            },
            [screenSizes.desktop]: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              allowTouchMove: false,
              pagination: false,
            },
          },
        }}
      >
        {products.map(product => {
          const images = getAllImagesSmall(product)
          return (
            <SwiperSlide key={product.name}>
              <Title>{product.name}</Title>
              <Description>{product.oneLineDescription}</Description>
              <Body>
                <Image>
                  <GLink to={`/product/${product.slug}`}>
                    <FlipImage image={images[0]} secondImage={images[1]} />
                  </GLink>
                </Image>

                <Contents>
                  <div dangerouslySetInnerHTML={{ __html: product.contents }} />
                  <Price>For Only {getPriceFromProduct(product, null)}</Price>
                </Contents>
              </Body>
              <Buttons>
                {!product.variants && (
                  <Input
                    type="number"
                    value={quantity}
                    onChange={event => setQuantity(event.target.value)}
                  />
                )}
                {!product.variants && (
                  <AddButton onClick={() => addToBasket(product)}>Add to Basket</AddButton>
                )}
                <LearnMoreButton to={`/product/${product.slug}`}>Learn More</LearnMoreButton>
              </Buttons>
            </SwiperSlide>
          )
        })}
      </Carousel>
    </Container>
  )
}

export default ProductFeatureCarousel
