import * as React from "react"
import styled from "styled-components"
import FeatureTitle from "../title/FeatureTitle"
// @ts-ignore
import Arrow from "../../../svg/arrow.inline.svg"
import { primaryLight } from "../../constants/colors"
import { Swiper } from "swiper/react"
import { useEffect, useRef, useState } from "react"
import SwiperClass from "swiper/types/swiper-class"
import { Fade } from "react-reveal"
import { screenSizes } from "@social-supermarket/social-supermarket-components"

const Container = styled.div<{ height: number }>`
  min-height: ${({ height }) => (height ? `${height}px` : "initial")};
`
const Title = styled.h2`
  margin-bottom: 40px;
`
const SwiperContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  flex: 1;
`
const ArrowButton = styled.button`
  display: ${({ hide }) => (hide ? "hide" : "block")};
  visibility: ${({ hide }) => (hide ? "hidden" : "visible")};
  height: 70px;
  width: 70px;
  padding: 0 15px;
  background: none;
  border: none;
  cursor: pointer;
  fill: ${primaryLight};

  &:hover {
    svg {
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
    }
  }

  &:active {
    svg {
      filter: drop-shadow(0 0 0px rgba(0, 0, 0, 0.3));
    }
  }

  &:focus {
    border: none !important;
    outline: none;
  }

  @media (max-width: ${screenSizes.desktop}px) {
    display: none;
  }
`
const LeftArrowButton = styled(ArrowButton)`
  transform: scaleX(-1);
`

const BREAK_POINTS = {
  [screenSizes.smallMobile]: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  [screenSizes.tablet]: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  [screenSizes.desktop]: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    allowTouchMove: false,
    pagination: false,
  },
}

interface Props {
  title?: string
  showArrows?: boolean
  loop?: boolean
  children: React.ReactNode
  swiperProps?: object
}

const Carousel = ({
  title,
  showArrows = false,
  loop = false,
  children,
  swiperProps = {},
}: Props) => {
  const [swiper, setSwiper] = useState<SwiperClass>(null)
  const [show, setShow] = useState<boolean>(true)
  const ref = useRef(null)
  const [height, setHeight] = useState<number>()

  // @ts-ignore
  const hideArrows = swiper && swiper.snapGrid?.length <= 1

  useEffect(() => {
    setHeight(ref.current.offsetHeight)
    setShow(false)
    setTimeout(() => {
      setShow(true)
    }, 50)
  }, [])

  return (
    <Container ref={ref} height={height}>
      {title && <FeatureTitle title={title} style={{ marginBottom: 40 }} />}
      {show && (
        <Fade>
          <SwiperContainer>
            {showArrows && (
              <LeftArrowButton onClick={() => swiper && swiper.slidePrev()} hide={hideArrows}>
                <Arrow />
              </LeftArrowButton>
            )}

            <Swiper
              onSwiper={swiper => setSwiper(swiper)}
              slidesPerView={1}
              slidesPerGroup={1}
              pagination={{ clickable: true }}
              spaceBetween={10}
              style={{
                width: "100%",
                maxWidth: 1200,
                margin: "auto",
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
              breakpoints={BREAK_POINTS}
              loop={loop}
              {...swiperProps}
            >
              {children}
            </Swiper>

            {showArrows && (
              <ArrowButton onClick={() => swiper && swiper.slideNext()} hide={hideArrows}>
                <Arrow />
              </ArrowButton>
            )}
          </SwiperContainer>
        </Fade>
      )}
    </Container>
  )
}

export default Carousel
